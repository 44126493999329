@import "../../variables.scss";
@import "../../mixins.scss";

.partners-component {
  margin: 40px auto 0px;

  .partners-wrapper {
    margin: 0 -20px 0px;
    display: flex;
    flex-wrap: wrap;

    a {
      color: black;
      text-decoration: none;
    }

    .partner-title {
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .partner-col {
      width: 33.3%;
      padding: 20px 20px;

      @include devices(tablet) {
        width: 50%;
      }

      @include devices(mobile) {
        width: 100%;
      }

      .partner-inner {
        padding: 9px 10px;
        border: 2px solid rgba(black, 0.2);
        border-radius: 14px;

        img {
          display: block;
          width: 100%;
          border-radius: 8px;
        }
      }
    }
  }
}
