@import "./variables.scss";
@import "./mixins.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";


body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  color: #000;
}
strong {
  font-weight: 500;
}
.page-title {
  margin-bottom: 30px;
}

html {
  font-size: 14px;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.form-row {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  color: $color-success;
}

.form-control {
  display: block;
  background: white;
  width: 100%;
  padding: 7px 7px;
  margin: 0 0 10px;
  border: 1px solid $text-muted;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.5;

  &.input {
    max-width: 200px;
  }
}

.container-nav {
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.container {
  width: calc(100% - 60px);
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.col-half {
  width: 50%;
}

.no-data-message {
  margin: 40px 0 10px;
  font-size: 14px;
  text-align: center;
}

